html,
body {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #FAFFF7;
}

* {
  box-sizing: border-box;
}

:root {
  --blue: black;
  --green: #8BE1CF;
  --light: 300;
  --regular: 400;
  --medium: 500;
  --semibold: 600;
  --bold: 700;
}

a {
  color: var(--blue);
  transition: 0.3s;
  cursor: pointer;
}

a:hover {
  opacity: 0.8;
}

img {
  max-width: 100%;
}

.container {
  margin: 0 auto;
}

.w1385 {
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.w1385 {
  max-width: 1385px;
  margin: 0 auto;
}

.anchor {
  scroll-margin-top: 70px;
}

section {
  padding-top: 50px;
}

.std-iframe {
  width: 100%;
  overflow: hidden;
  border: 0;
}

.std-iframe iframe {
  width: 100%;
  border: none;
}

.std-title {
  font-size: 30px;
  font-weight: var(--medium);
  color: var(--blue);
  border-bottom: 1px solid var(--blue);
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.std-writeup {
  color: #333;
  line-height: 160%;
  font-size: 18px;
}

.std-title.white {
  color: #fff;
  border-color: #fff;
}

.std-writeup.white {
  color: #fff;
}

.std-title.no-border {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

/*Header*/
header {
  position: relative;
  background: #d7edf9;
}

header.transparent-bg {
  background: transparent;
}

header.full-height {
  height: 95vh;
}

header.full-height .header-content {
  padding-bottom: 0;
  height: 100%;
  display: flex;
}

header.full-height .header-content .big-title {
  top: unset;
}

header.full-height .header-content .wrapper {
  width: 100%;
}

header .logo img {
  max-height: 125%;
}

header .video-container {
  position: relative;
  top: 52px;
  background: #94d3a2;
  height: 266px;
}

header video {
  display: block;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.fade-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  pointer-events: none;
}

header .logo-menu {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  transition: 0.3s;
  display: none;
}

header .logo-menu.relative {
  position: relative;
}

header .logo-menu.sticky {
  position: fixed;
  background-color: #FAFFF7;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
}

header .logo-menu.sticky .content {
  padding-top: 20px;
  padding-bottom: 15px;
}

header .logo-menu.sticky .socials img {
  height: 15px;
}

header .logo-menu .content {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

header .logo {
  height: 50px;
}

header .socials {
  gap: 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

header .socials img {
  height: 25px;
  transition: 0;
}

header .big-title {
  font-size: 25px;
  color: var(--blue);
  font-weight: var(--medium);
  text-align: center;
  position: absolute;
  z-index: 1;
  max-width: 760px;
  left: 0;
  right: 0;
  top: 38%;
  margin: 0 auto;
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeInMoveDown 1s forwards;
}

@keyframes fadeInMoveDown {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

header nav {
  border-top: 1px solid var(--blue);
  margin-top: 10px;
  padding-top: 0px;
  padding-left: 10px;
  display: flex;
  gap: 0 10px;
  align-items: center;
}

header nav ul {
  padding: 0;
  margin: 0;
  display: flex;
  gap: 0 5px;
}

header nav ul li {
  list-style-type: none;
  position: relative;
  
}

header nav ul li a {
  text-decoration: none;
  padding: 10px 30px;
  display: inline-block;
}


/* Remove the arrow for links without dropdown */
header nav ul li>a:after {
  display: none; /* Hide the arrow by default */
  
}

/* Show the arrow only for links that have a dropdown */
header nav ul li.has-dropdown > a:after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}


header nav ul li {
  position: relative;
  
}

header nav ul li .dropdown {
  display: flex;
  flex-direction: column;
  max-height: 0;
  position: absolute;
  visibility: hidden;
  transition: max-height 0.5s ease, visibility 0.5s ease;
  overflow: hidden;
  width: 100%;
  border-top: 1px solid #fff;
}

header nav ul li a {
  transition: 0.5s;
  color: var(--blue);
  
}

header nav ul li a:hover,
header nav ul li:hover>a {
  background-color: var(--blue);
  color: #fff;
  opacity: 1;
}

header nav ul li .dropdown a {
  padding: 10px;
  text-align: center;
  background-color: var(--blue);
  color: #fff;
  border-bottom: 1px solid #fff;
  width: 100%;
  transition: 0.3s;
  font-size: 15px;
}

header nav ul li .dropdown a:last-of-type {
  border-bottom: 0;
}

header nav ul li .dropdown a:hover {
  background-color: var(--green);
  color: var(--blue);
  opacity: 1;
}

header nav ul li:hover .dropdown {
  max-height: 500px;
  visibility: visible;
}

header nav .open-search {
  width: 30px;
  cursor: pointer;
  text-align: right;
  transition: 0.3s;
}

header nav .open-search img {
  display: block;
  height: 22px;
}

header nav .open-search:hover {
  opacity: 0.8;
}

.header-content {
  padding-top: 120px;
  padding-bottom: 50px;
}

.header-content .big-title {
  position: relative;
  animation: none;
  opacity: 1;
  transform: unset;
}

.header-content .subtitle {
  text-align: center;
  color: var(--blue);
  font-size: 22px;
  margin-top: 10px;
  line-height: 140%;
  animation: fadeInMoveDown 1s forwards;
}

.header-content .three-cards {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  padding-bottom: 10px;
  flex-direction: column;
  gap: 30px 0;
}

.header-content .three-cards .item {
  width: 85%;
  background-color: #FAFFF7;
  border-radius: 20px;
  padding: 15px;
  box-shadow: -10px 10px 0 0 #8be1cf, -10px 10px 0 0 #8be1cf;
  cursor: pointer;
  transition: 0.3s;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  align-items: center;
  gap: 0 20px;
}

.header-content .three-cards .item:first-of-type {}

.header-content .three-cards .item:hover {
  transform: translate(-10px, 10px);
  box-shadow: none;
  opacity: 1;
}

.header-content .three-cards .icon {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 15%;
  margin: 10px auto 0 auto;
}

.header-content .three-cards .icon img {
  max-height: 100%;
}

.header-content .three-cards .title {
  text-align: center;
  font-size: 22px;
  text-decoration: none;
  font-weight: var(--medium);
  margin-top: 10px;
}

.header-content .image {
  text-align: center;
  margin-top: 30px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

/* Mobile Header */
.mobile-header {
  background-color: var(--blue);
  width: 100%;
  position: fixed;
  z-index: 3;
  padding: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  gap: 0 20px;
}

.mobile-header .logo {
  height: 40px;
  margin-right: auto;
}

.mobile-header .logo img {
  display: block;
  max-height: 100%;
}

.mobile-header .open-search {
  height: 27px;
}

.mobile-header .open-search img {
  max-height: 100%;
}

.mobile-header .hamburger {
  width: 30px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.mobile-header .hamburger .patty {
  background-color: #fff;
  height: 3px;
  border-radius: 2px;
  transition: 0.3s;
}

.mobile-header .hamburger.active .patty:nth-of-type(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.mobile-header .hamburger.active .patty:nth-of-type(2) {
  opacity: 0;
}

.mobile-header .hamburger.active .patty:nth-of-type(3) {
  transform: rotate(-45deg) translate(8px, -8px);
}

.slidemenu {
  top: -620px;
  position: fixed;
  background-color: #fff;
  width: 100%;
  z-index: 2;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

.slidemenu.active {
  top: 70px;
  padding: 25px 15px 15px 15px;
}

.slidemenu ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.slidemenu ul li {
  list-style-type: none;
}

.slidemenu a {
  text-decoration: none;
}

.slidemenu .title {
  margin-bottom: 13px;
  font-weight: var(--medium);
  font-size: 22px;
  display: block;
}

.slidemenu .content {
  display: flex;
  flex-direction: column;
  gap: 15px 0;
  text-align: center;
}

.slidemenu .group {
  padding-bottom: 15px;
}

.slidemenu .group.socials {
  display: flex;
  gap: 0 10px;
  border-bottom: 0;
  justify-content: center;
}

.slidemenu .group.socials img {
  height: 25px;
}



/* Footer */
footer {
  background-color: var(--blue);
  margin-top: 50px;
  padding: 50px 0;
}

footer .row-two {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #fff;
  padding-top: 25px;
  margin-top: 15px;
  flex-wrap: wrap;
}

footer .col {
  width: 100%;
  color: #fff;
  font-size: 15px;
  border-bottom: 1px solid #fff;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

footer .col .title {
  margin-bottom: 18px;
  font-weight: var(--bold);
  color: #fff;
  font-size: 21px;
  display: inline-block;
  text-decoration: none;
}

footer .col ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px 0;
}

footer .col ul li {
  list-style-type: none;
}

footer .col ul li a {
  color: #fff;
  text-decoration: none;
}

footer .col ul li a:hover {
  text-decoration: underline;
}

footer .col form .subscribe-field {
  border: 0;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
}

footer .col form .subscribe-field:focus {
  outline: none;
}

footer .col form .subscribe-submit {
  background-color: var(--green);
  border: 0;
  padding: 10px;
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
  color: #fff;
  font-size: 15px;
  font-weight: var(--semibold);
  cursor: pointer;
  transition: 0.3s;
}

footer .col form .subscribe-submit:hover {
  opacity: 0.8;
}

footer .col.about-work .mt-20 {
  margin-top: 20px;
}

footer .email {
  text-decoration: underline;
}

footer .map {
  margin-bottom: 10px;
  padding-bottom: 0;
  border-bottom: 0;
  position: relative;
}

footer .map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

footer .col.subscribe {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

footer .btt {
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 40px;
  cursor: pointer;
  transition: 0.3s;
}

footer .btt:hover {
  opacity: 0.8;
  ;
}

footer .btt img {
  max-height: 100%;
}









/* my css */


header {
  color: #545454;
}
header a {
  color: #545454;
}
header .socials img {
filter: brightness(0) saturate(100%) invert(60%) sepia(10%) saturate(400%) hue-rotate(60deg) brightness(95%) contrast(300%);
transition: filter 0.3s ease;

}
/* margla about  */
.about-section {
background-color: #94d3a2; /* Green background color */
padding: 50px 20px;
color: #ffffff; /* White text color */
}

.about-container {
max-width: 1200px;
margin: 0 auto;
}

.about-section h2 {
font-size: 36px;
font-weight: bold;
margin-bottom: 20px;
color: #ffffff;
}

.divider {
border: none;
border-top: 2px solid #ffffff;
width: 100%;
margin: 20px 0;
}

.about-content {
display: flex;
justify-content: space-between;
align-items: flex-start;
gap: 20px;
}

.about-text {
flex: 1;
font-size: 18px;
line-height: 1.6;
color: #ffffff;
padding-right: 20px;
}

.about-video {
flex: 0 0 480px; /* Fixed width for the video */
border-radius: 8px;
overflow: hidden;
}

.about-video iframe {
width: 100%;
height: 270px; /* Adjust height as needed */
border-radius: 8px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.download-button {
display: inline-block;
padding: 12px 20px;
background-color: #ffffff;
color: #94d3a2;
font-size: 18px;
font-weight: bold;
text-align: center;
text-decoration: none;
border-radius: 5px;
transition: background-color 0.3s ease;
margin-top: 20px;
}

.download-button:hover {
background-color: #e0e0e0;
}



/* Eligibility Criteria */

.criteria-section {
background-color: #e8f0e8; /* Light green background */
padding: 50px 20px;
color: #333; /* Text color */
}

.content-container {
max-width: 1213px;
margin: 0 auto;
}

.criteria-section h2 {
font-size: 24px;
font-weight: bold;
color: #545454;
margin-bottom: 10px;
}

.divider {
border: none;
border-top: 2px solid #545454;
width: 100%;
margin-bottom: 20px;
}

.criteria-list {
list-style-type: decimal;
padding-left: 20px;
margin-bottom: 30px;
font-size: 16px;
line-height: 1.8;
}

.apply-button {
display: inline-block;
padding: 10px 20px;
background-color: #94d3a2; /* Green button background */
color: #ffffff;
font-size: 18px;
font-weight: bold;
text-align: center;
text-decoration: none;
border-radius: 5px;
transition: background-color 0.3s ease;
margin-top: 20px;
}

.apply-button:hover {
background-color: #6ea473;
}


.rf-about-section {
background-color: #f4f8f4; /* Light green background */
padding: 50px 20px;
color: #333; /* Text color */
}

.rf-content-container {
max-width: 1200px;
margin: 0 auto;
display: flex;
justify-content: space-between;
align-items: flex-start;
gap: 20px;
}

.rf-text-content {
background-color: #0088b9; /* Blue background */
color: #ffffff; /* White text */
padding: 20px;
border-radius: 10px;
width: 65%; /* Adjust as needed */
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.rf-text-content h2 {
font-size: 24px;
font-weight: bold;
margin-bottom: 20px;
color: #ffffff; /* White text */

}

.rf-text-content p {
font-size: 16px;
line-height: 1.6;
margin-bottom: 20px;

}

.rf-twitter-content {
background-color: #ffffff; /* White background */
padding: 20px;
border-radius: 10px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
width: 30%; /* Adjust as needed */
}

.rf-twitter-content .rf-twitter-timeline {
display: block;
width: 100%;
height: 400px; /* Adjust height as needed */
}

.faq-section {
background-color: #f4f8f4; /* Light green background */
padding: 50px 20px;
color: #333; /* Text color */
}

.faq-container {
max-width: 1200px;
margin: 0 auto;
font-family: Arial, sans-serif;
padding-top: 85px;
}

.faq-section h2 {
font-size: 32px;
font-weight: bold;
color: #545454;
margin-bottom: 20px;
}

.divider {
border: none;
border-top: 2px solid #f7f2f2;
width: 100%;
margin-bottom: 20px;
}

.faq-item {
border: 1px solid #6ea473; /* Green border */
margin-bottom: 10px;
border-radius: 5px;
overflow: hidden;
}

.faq-item label {
display: block;
padding: 15px;
font-size: 18px;
font-weight: bold;
cursor: pointer;
position: relative;
}

.faq-item label::after {
content: '+';
font-size: 24px;
position: absolute;
right: 20px;
top: 50%;
transform: translateY(-50%);
color: #6ea473; /* Green color */
transition: transform 0.3s ease;
}

.faq-item input[type="checkbox"] {
display: none;
}

.faq-item input[type="checkbox"]:checked + label::after {
content: '-';
transform: translateY(-50%) rotate(180deg);
}

.faq-content {
display: none;
padding: 0 15px 15px;
font-size: 16px;
}

.faq-item input[type="checkbox"]:checked ~ .faq-content {
display: block;
}

.faq-note {
margin-top: 20px;
padding: 15px;
border: 1px solid #6ea473;
border-radius: 5px;
font-size: 16px;
line-height: 1.6;
background-color: #ffffff;
}
/* footer */
footer {
background-color: #94d3a2; /* Green background color */
padding: 20px 0;
color: #ffffff; /* White text color */
}

footer .content {
margin: 0 auto;
}

footer a {
color: #ffffff; /* White text color for links */
text-decoration: none;
}

footer a:hover {
color: #e0e0e0; /* Slightly lighter color on hover */
}

footer .title {
font-weight: bold;
margin-bottom: 10px;
font-size: 18px;
}

footer .map iframe {
border: none;
}

footer .btt img {
width: 50px; /* Adjust as needed */
}

footer .col {
margin-bottom: 20px;
}

footer .subscribe .title {
margin-bottom: 15px;
}

footer .subscribe input[type="text"] {
width: 100%;
padding: 10px;
margin-bottom: 10px;
border-radius: 5px;
border: 1px solid #cccccc;
}

footer .subscribe input[type="submit"] {
background-color: #4a90e2; /* Button color */
color: #ffffff;
padding: 10px 20px;
border: none;
border-radius: 5px;
cursor: pointer;
}

footer .subscribe input[type="submit"]:hover {
background-color: #357ab7; /* Darker button color on hover */
}

footer .social-icons img {
width: 32px;
height: 32px;
margin-right: 10px;
}



/* Quicklinks */
.quicklinks-feed {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px 0;
}

.quicklinks {
  /* background-color: #cce7f1; */
  border-radius: 20px;
  padding: 50px;
  max-width: fit-content;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(100% - 30px);
}

.quicklinks .title-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quicklinks .title-nav .title {
  font-size: 30px;
  font-weight: var(--bold);
  color: var(--blue);
}

.quicklinks .title-nav .nav {
  display: flex;
  gap: 0 5px;
}

.quicklinks .title-nav .nav .prev,
.quicklinks .title-nav .nav .next {
  height: 30px;
  cursor: pointer;
  transition: 0.3s;
}

.quicklinks .title-nav .nav .prev img,
.quicklinks .title-nav .nav .next img {
  height: 100%;
}

.quicklinks .title-nav .nav .prev:hover,
.quicklinks .title-nav .nav .next:hover {
  opacity: 0.8;
}

.quicklinks .title-nav .nav .slick-disabled {
  opacity: 0.2;
  pointer-events: none;
}

.quicklinks .title-nav .nav .next {
  transform: rotate(180deg);
}

.quicklinks .carousel {
  border-top: 1px solid var(--blue);
  padding-top: 20px;
  margin-top: 15px;
}

.quicklinks .carousel .item {
  margin: 0 10px;
  text-decoration: none;
}

.quicklinks .carousel .slick-list {
  margin-left: -10px;
  margin-right: -10px;
}

.quicklinks .carousel .item .thumb img {
  border-radius: 5px;
  object-fit: cover;
    object-position: top center;
}

.quicklinks .carousel .item .title {
  margin-top: 20px;
  font-weight: var(--medium);
  color: #333;
  line-height: 130%;
}

.quicklinks .carousel .item .date {
  margin-top: 12px;
  color: #333;
}

.quicklinks-feed .quicklinks {
  margin-left: unset;
  margin-right: unset;
  width: 100%;
}

.quicklinks.team {
  padding: 15px 15px;
  max-width: unset;
  margin-top: 30px;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.quicklinks.team .nav {
  margin-left: auto;
}

.quicklinks.team .item {
  position: relative;
}

.quicklinks.team .item .team-tab {
  position: absolute;
  background-color: #FAFFF7;
  border-radius: 5px 0 0 5px;
  bottom: 20px;
  right: 0;
  width: 90%;
  font-size: 20px;
  padding: 10px;
  box-shadow: -5px 5px 0 0 #8be1cf, -5px 5px 0 0 #8be1cf;
  font-weight: var(--bold);
}

.quicklinks .carousel .item .thumb {
  height: 320px;
  display: -webkit-inline-box;
}

.quicklinks.team .item .team-tab .name {}

.quicklinks.team .item .team-tab .position {}

.quicklinks .carousel {
  padding-top: 20px;
  margin-top: 15px;
}
.btn-primary{
  background-color: black !important;
  border-color: black !important; /* Optional: Match the border color */

}
.btn-outline-primary{
  background-color: black !important;
  --bs-btn-color:white !important
}
.btn-outline-primary{
  --bs-btn-hover-color:white
}
.btn-outline-primary:hover{
  box-shadow:0 8px 25px -8px black;
}
.btn-primary:hover{
  box-shadow:0 8px 25px -8px black;

}
.form-group label{
  padding-right: 10px;
}
.list-group-item.active{
  background-color: black;
  border-color: black;
}
.btn-danger{
  --bs-btn-bg:black;
}
.spinner-bubble-primary {
  color: black; /* Set the spinner color to black */
}
.pagination {
  display: flex;
  list-style-type: none;
  padding: 0;
  justify-content: center;
  gap: 10px;
  padding-top: 20px;
}

.pagination li {
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #f0f0f0;
}

.pagination li.active {
  background-color: #70bf6a;
  color: white;
}

.pagination li:hover {
  background-color: #ddd;
}

.pagination li.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}