/* Extra small devices (portrait phones, less than 576px)
/* No media query for `xs` since this is the default in Bootstrap

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
	header .video-container {
		height: 384px;
		top: 70px;
		overflow: hidden;
	}

	header .big-title {
		top: 40%;
		font-size: 33px;
	}

	.resources-pg .categories .item {}

	.header-content .three-cards .icon {
		width: 10%;
	}

	.header-content .three-cards .item {
		padding: 20px;
	}

	.header-content {
		padding-top: 110px;
	}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
	.logo-menu {
		display: block;
	}

	.mobile-header {
		display: none;
	}

	header .video-container {
		height: 418px;
		top: 0;
	}

	header .logo-menu {
		display: block;
	}

	header .big-title {
		top: 33%;
		font-size: 40px;
	}

	header .logo-menu.sticky .content {
		padding-bottom: 10px;
	}

	nav ul li a {
		padding: 10px 25px;
	}

	.our-approaches .content .title {
		font-size: 40px;
	}

	.our-programmes .title {
		font-size: 40px;
	}

	.our-approaches {
		padding-top: 50px;
	}

	.our-approaches .content .writeup {
		font-size: 18px;
	}

	.our-programmes .writeup {
		font-size: 18px;
	}

	.our-approaches .content .three-cards {
		flex-direction: row;
		justify-content: center;
		gap: 0 3%;
	}

	.our-approaches .content .three-cards .item {
		width: 30%;
		margin-left: unset;
		margin-right: unset;
	}

	.our-approaches .content .three-cards .item .initial {
		font-size: 23px;
	}

	.our-programmes .three-cards {
		flex-direction: row;
	}

	.our-programmes .three-cards .item {
		width: 32%;
		margin-left: unset;
		margin-right: unset;
	}

	.our-programmes .three-cards .item .tag {
		font-size: 14px;
	}

	.quicklinks-feed {
		flex-direction: row;
		padding-top: 70px;
	}

	.quicklinks-feed .quicklinks {
		width: 68%;
		margin-left: unset;
		margin-right: unset;
	}

	.quicklinks-feed .feed {
		width: 30%;
	}

	footer .col.map {
		width: 100%;
		height: 210px;
		margin-bottom: 25px;
	}

	footer .col.contact {
		width: 23%;
	}

	footer .col.about-work {
		width: 16%;
	}

	footer .col.resources {
		width: 15%;
	}

	footer .col.subscribe {
		width: 28%;
	}

	footer .col {
		margin-bottom: 0;
		padding-bottom: 0;
		border-bottom: 0;
	}

	.slidemenu {
		display: none;
	}

	.header-content .three-cards {
		flex-direction: row;
		justify-content: center;
		gap: 0 3%;
	}

	.who-we-are .content .title {
		font-size: 32px;
	}

	.header-content .three-cards .item {
		width: 30%;
		margin-left: unset;
		margin-right: unset;
		display: block;
		height: 0;
		padding: 0 0 30% 0;
	}

	.header-content .three-cards .item .content {
		position: relative;
		top: 40px;
	}

	.header-content .three-cards .icon {
		width: 100%;
		height: 75px;
	}

	.header-content .three-cards .icon.whoweare img {}

	.header-content .three-cards .title {
		margin-top: 20px;
		font-size: 30px;
	}

	.header-content {
		padding-top: 0;
		position: relative;
		align-items: center;
	}

	.who-we-are .two-cards {
		display: block;
		position: relative;
		margin-top: 60px;
	}

	.who-we-are .two-cards .card {
		width: 47.5%;
		margin-left: unset;
		margin-right: unset;
	}

	.our-team .team-tree {
		width: 85%;
	}

	.our-team .team-tree .member {
		width: 30%;
	}

	.who-we-are .two-cards .card.one {
		margin-left: 10px;
		width: calc(50% + 20px);
		padding-right: 70px;
	}

	.who-we-are .two-cards .card.two {
		box-shadow: unset;
		position: absolute;
		right: 0;
		top: -30px;
		width: calc(50% + 20px);
		margin-top: 0;
	}

	.resources-pg .resources-listing .item {}

	.resources-pg .resources-listing .item {}

	.resources-pg .resources-listing .item .thumb {}

	.resources-pg .resources-listing .item .date {
		margin-top: 15px;
	}

	.resources-pg .categories .item {}

	.resources-pg .resources-listing .item .text-wrap {}

	.std-title {
		font-size: 35px;
	}

	.search-overlay .overlay .close {
		right: 30px;
		top: 30px;
	}

	.who-we-are {
		background: linear-gradient(to bottom, var(--blue) 82%, #FAFFF7 40%);
	}

	.resources-pg .two-col {
		display: flex;
		flex-direction: row;
		gap: 0 3%;
	}

	.resources-pg .two-col .left {
		width: 55%;
	}

	.resources-pg .two-col .right {
		width: 42%;
	}

	.resources-pg .breadcrumb {
		font-size: 23px;
		height: 54px;
		align-items: center;
	}

	.resources-pg .resources-listing .article-title {
		font-size: 20px;
	}

	.resources-pg .resources-listing .item {
		gap: 0 20px;
	}

	.resources-pg .resources-listing .item .text-wrap {
		width: calc(60% - 20px);
	}

	.pagination .item {
		width: 50px;
		height: 50px;
	}

	footer .map iframe {
		position: relative;
	}

	.resources-pg {
		padding-top: 30px;
	}

	.quicklinks {
		padding: 30px;
	}

	.header-content .three-cards .item:first-of-type {
		margin-left: 10px;
	}

	.our-work .three-column {
		flex-direction: row;
	}

	.our-work .three-column .col.one {
		width: 25%;
	}

	.our-work .three-column .col.two {
		width: 49%;
	}

	.our-work .three-column .col.three {
		width: 20%;
	}
	.rf-about-section {
	
		padding: 50px 70px;
	
		}
		.criteria-section {
			
			padding: 50px 70px;

		}
		.about-section {
			
			padding: 50px 70px;
	
		}
		.faq-container {
			max-width: 850px;
			
			}
			.w1385 {
				max-width: 890px;
			}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
	header .video-container {
		height: 540px;
	}

	header .big-title {
		font-size: 45px;
	}

	nav ul li a {
		padding: 10px 30px;
	}

	.our-approaches .content .three-cards .item .initial {
		font-size: 29px;
	}

	.our-approaches .content .writeup {
		font-size: 22px;
	}

	.our-programmes .writeup {
		font-size: 20px;
	}

	.our-programmes .three-cards .item .tag {
		font-size: 19px;
	}

	.our-programmes .three-cards .item {
		width: 31.5%;
	}

	.our-approaches .content .three-cards .item .initial .hint {
		display: none;
	}

	footer .col.map {
		width: 28%;
	}

	footer .col.contact {
		width: 18%;
	}

	footer .col.about-work {
		width: 14%;
	}

	footer .col.resources {
		width: 11%;
	}

	footer .col.subscribe {
		width: 19%;
	}

	.header-content .three-cards .item {
		display: block;
	}

	.header-content .three-cards .icon {}

	.who-we-are .two-cards .card {
		width: calc(50% + 20px);
		padding: 60px 50px;
	}

	.resources-pg .resources-listing .item {}

	.resources-pg .resources-listing {
		gap: 30px 2.6666%;
	}

	.search-overlay .overlay .content {
		padding: 0;
	}

	.quicklinks {
		width: 100% !important;
		margin-left: auto;
		margin-right: auto;
		padding: 0px;
	}

	.who-we-are .two-cards .card .writeup {
		font-size: 20px;
		margin-top: 20px;
	}

	.who-we-are .std-writeup {
		margin-top: 20px;
	}

	.header-content .three-cards .icon {
		margin-top: unset;
		height: 100px;
	}

	.std-writeup {
		font-size: 20px;
	}

	.who-we-are .two-cards {
		margin-top: 80px;
	}

	.who-we-are .content .title {
		font-size: 32px;
	}

	.who-we-are .two-cards .card.two {
		width: calc(50% + 20px);
		top: -70px;
	}

	.who-we-are .two-cards .card .title {
		font-size: 30px;
	}

	.quicklinks.team {
		padding: 30px 50px 50px 50px;
	}

	.quicklinks.team .item .team-tab {
		font-size: 18px;
		padding: 15px 15px;
	}

	.joinus .job-listing .position .details {
		font-size: 18px;
	}

	.who-we-are {
		background: linear-gradient(to bottom, var(--blue) 76%, #FAFFF7 40%);
	}

	.our-approaches .content .three-cards {
		gap: 0 3%;
	}

	.our-approaches .content .three-cards .item {
		width: 30.5%;
	}

	.our-approaches {
		background: linear-gradient(to bottom, var(--blue) 65%, #FAFFF7 40%);
	}

	.header-content .three-cards .item .content {
		top: 69px;
	}

	footer .col.map {
		width: 18%;
		height: 0;
		padding-bottom: 18%;
		margin-bottom: 0;
		position: relative;
	}

	footer .col.contact {
		width: 18%;
	}

	footer .col.about-work {
		width: 18%;
	}

	footer .col.resources {
		width: 18%;
	}

	footer .col.subscribe {
		width: 18%;
	}

	footer .map iframe {
		position: absolute;
	}
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	.w1385 {
		max-width: 1240px;
	}

	header .video-container {
		position: relative;
		height: unset;
	}

	header .big-title {
		font-size: 45px;
	}

	section {
		padding-top: 80px;
	}

	.our-approaches .content .three-cards {
		justify-content: space-between;
	}

	.our-programmes .three-cards .item .tag {
		font-size: 24px;
	}

	footer .col.map {
		width: 18%;
		height: 0;
		height: 0;
		padding-bottom: 20%;
		margin-bottom: 0;
		position: relative;
	}

	footer .col.contact {
		width: 18%;
	}

	footer .col.about-work {
		width: 18%;
	}

	footer .col.resources {
		width: 18%;
	}

	footer .col.subscribe {
		width: 18%;
	}

	.resources-pg .resources-listing .item {}

	.who-we-are .two-cards {
		margin-top: 120px;
	}

	.who-we-are {
		background: linear-gradient(to bottom, var(--blue) 77%, #FAFFF7 40%);
	}

	.quicklinks.team .item .team-tab {
		bottom: 30px;
	}

	.quicklinks-feed .quicklinks {
		width: 68%;
		max-width: unset;
	}

	.header-content .three-cards .item {
		width: 25%;
		padding: 0 0 25% 0;
	}

	.header-content .three-cards .item .content {
		top: 100px;
	}
	.quicklinks {
		width: 97% !important;
	
	}
	.rf-about-section {
	
		padding: 50px 20px;
	
		}
		.criteria-section {
			
			padding: 50px 20px;

		}
		.about-section {
			
			padding: 50px 20px;
	
		}
		.faq-container {
			max-width: 1200px;
			
			}
}

/* Super High-res Macs */
@media (min-width: 1385px) {
	header.full-height .header-content {}

	header video {
		max-width: none;
		width: 100%;
	}

	header .big-title {
		top: 23%;
		font-size: 40px;
	}

	header video {}

	.quicklinks {
		padding: 0px;
	}

	.header-content .three-cards .item {
		width: 25%;
		padding: 0 0 25% 0;
	}

	.header-content .three-cards .item .content {
		top: 90px;
	}
}