body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-right {
  text-align: right !important;
}
.card-icon-bg .card-body .content.custom {
  align-items: unset !important;
  max-width: unset !important;
}
/* Privacy */
.privacy-formatting strong {
  display: block;
}
.privacy-formatting div {
  margin-bottom: 10px;
  padding-left: 16px;
}
.privacy-formatting div strong {
  display: inline-block;
}

.btn-outline-primary.btn-outline-email:hover {
  color: #fff;
}